/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * TORCH(Scope3)API
 * TORCH(Scope3)API定義
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  OldGetCategory4200,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  OldGetCategory4Params,
  GetCategory4200,
  GetCategory4Params,
} from "../model";
import { rest } from "msw";
import { faker } from "@faker-js/faker";
import { FactoryType, IntensityType } from "../model";
import { customMutator } from "../custom-mutator";
import type { ErrorType } from "../custom-mutator";

/**
 * カテゴリ4情報を返却する。（主にグラフ利用）
サプライヤ企業のみ認可あり
 * @summary カテゴリ4取得
 */
export const oldGetCategory4 = (
  params: OldGetCategory4Params,
  signal?: AbortSignal
) => {
  return customMutator<OldGetCategory4200>({
    url: `/old/category4`,
    method: "get",
    params,
    signal,
  });
};

export const getOldGetCategory4QueryKey = (params: OldGetCategory4Params) => [
  `/old/category4`,
  ...(params ? [params] : []),
];

export type OldGetCategory4QueryResult = NonNullable<
  Awaited<ReturnType<typeof oldGetCategory4>>
>;
export type OldGetCategory4QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useOldGetCategory4 = <
  TData = Awaited<ReturnType<typeof oldGetCategory4>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: OldGetCategory4Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof oldGetCategory4>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOldGetCategory4QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof oldGetCategory4>>> = ({
    signal,
  }) => oldGetCategory4(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof oldGetCategory4>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * カテゴリ4情報を返却する。（主にグラフ利用）
サプライヤ企業のみ認可あり
 * @summary カテゴリ4取得
 */
export const getCategory4 = (
  params: GetCategory4Params,
  signal?: AbortSignal
) => {
  return customMutator<GetCategory4200>({
    url: `/category4`,
    method: "get",
    params,
    signal,
  });
};

export const getGetCategory4QueryKey = (params: GetCategory4Params) => [
  `/category4`,
  ...(params ? [params] : []),
];

export type GetCategory4QueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategory4>>
>;
export type GetCategory4QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
>;

export const useGetCategory4 = <
  TData = Awaited<ReturnType<typeof getCategory4>>,
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse
  >
>(
  params: GetCategory4Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCategory4>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategory4QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategory4>>> = ({
    signal,
  }) => getCategory4(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCategory4>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const getOldGetCategory4Mock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  factories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    byDestination: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      factoryId: faker.random.word(),
      factoryName: faker.random.word(),
      factoryNameForSelf: faker.random.word(),
      factoryNameForOthers: faker.random.word(),
      factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
      co2Emission: faker.datatype.number({ min: 0, max: 10000000 }),
      co2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
  })),
  nationalAverage: faker.helpers.arrayElement([
    {
      co2EmissionByFactory: faker.datatype.number({ min: 0, max: 10000000 }),
      co2IntensityByDestination: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    },
    undefined,
  ]),
});

export const getGetCategory4Mock = () => ({
  companyId: faker.random.word(),
  year: faker.random.word(),
  factories: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    factoryId: faker.random.word(),
    factoryName: faker.random.word(),
    factoryNameForSelf: faker.random.word(),
    factoryNameForOthers: faker.random.word(),
    factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
    co2Emission: faker.helpers.arrayElement([
      faker.datatype.number({ min: 0, max: 10000000 }),
      undefined,
    ]),
    tonKmBasedCo2Intensity: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    byDestination: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      destinationId: faker.random.word(),
      factoryName: faker.random.word(),
      factoryNameForSelf: faker.random.word(),
      factoryNameForOthers: faker.random.word(),
      factoryType: faker.helpers.arrayElement(Object.values(FactoryType)),
      weightBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
      tonKmBasedCo2Intensity: faker.helpers.arrayElement([
        faker.datatype.number({ min: undefined, max: undefined }),
        undefined,
      ]),
    })),
    intensityType: faker.helpers.arrayElement(Object.values(IntensityType)),
    version: faker.datatype.number({ min: 0, max: 1000000000 }),
    deleteFlag: faker.datatype.boolean(),
  })),
});

export const getCategory4MSW = () => [
  rest.get("*/old/category4", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getOldGetCategory4Mock())
    );
  }),
  rest.get("*/category4", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetCategory4Mock())
    );
  }),
];
