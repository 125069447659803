import { Box, Tooltip, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { bindTextField, StringType, useForm } from "@somq14/use-form";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import { CompanyType, IntensityType } from "src/app/apis/model";
import { FactoryIntensity, DropdownProps } from "src/app/components/atoms";
import { Category1IntensityByProductTemplate } from "src/app/components/templates";
import {
  PaperCategory1DataForDisplay,
  ProcessCategory1DataForDisplay,
} from "src/app/hooks";
import {
  PaperFactoryCategory1FactoryDataForDisplay,
  ProcessFactoryCategory1FactoryDataForDisplay,
  TotalEmission,
} from "src/app/models";
import {
  getProcessNameForVisual,
  getUnitForCo2Intensity,
} from "src/app/utils/computeForVisuals";
import { DropDownItem } from "src/lib/components/atoms";

export type Category1IntensityByProductPageProps = {
  companyType: Exclude<CompanyType, "user-company">;
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay;
  isProcessing: boolean;
};

const convertCategory1DataToChartProps = (
  companyType: Exclude<CompanyType, "user-company">,
  selectedProduct: string,
  category1Data?: PaperCategory1DataForDisplay | ProcessCategory1DataForDisplay
) => {
  const chartData: TotalEmission[] =
    category1Data?.factories?.flatMap((factory) => {
      if (companyType === "process-supplier") {
        const tmpFactory =
          factory as ProcessFactoryCategory1FactoryDataForDisplay;

        if (tmpFactory.amountBasedCo2Intensity === undefined) {
          return [];
        }
        return [
          {
            dataName: factory.factoryNameForSelf,
            value: tmpFactory.amountBasedCo2Intensity,
          },
        ];
      } else {
        const tmpFactory =
          factory as PaperFactoryCategory1FactoryDataForDisplay;

        const selectedProductType = tmpFactory.byProduct.find(
          (it) => it.productType === selectedProduct
        );
        if (
          selectedProductType === undefined ||
          selectedProductType.amountBasedCo2Intensity === undefined
        ) {
          return [];
        }
        return [
          {
            dataName: factory.factoryNameForSelf,
            value: selectedProductType.amountBasedCo2Intensity,
          },
        ];
      }
    }) ?? [];

  return { chartData };
};

const convertCategory1DataToTableProps = (
  category1Data:
    | PaperCategory1DataForDisplay
    | ProcessCategory1DataForDisplay
    | undefined,
  companyType: Exclude<CompanyType, "user-company">
) => {
  const unit = getUnitForCo2Intensity(companyType);
  const processName = getProcessNameForVisual(companyType, "category1");

  const paperTableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 1,
    },
    {
      field: "productName",
      headerName: "製品",
      renderCell: (params) => DataCellWithTooltip(params),
      flex: 1,
    },
    {
      field: "co2Intensity",
      headerName: `${processName}原単位(${unit})`,
      flex: 1,
      renderCell: (params) => getIntensity(params),
    },
  ];

  const processTableColumns: GridColDef[] = [
    {
      field: "factoryName",
      headerName: "工場",
      flex: 2,
    },
    {
      field: "co2Intensity",
      headerName: `${processName}原単位(${unit})`,
      flex: 1,
      renderCell: (params) => getIntensity(params),
    },
  ];

  const tableColumns =
    companyType === "process-supplier"
      ? processTableColumns
      : paperTableColumns;

  const tableData = [];
  if (category1Data?.factories !== undefined) {
    for (const factory of category1Data.factories) {
      if (companyType === "process-supplier") {
        const tmpFactory =
          factory as ProcessFactoryCategory1FactoryDataForDisplay;
        if (tmpFactory.amountBasedCo2Intensity !== undefined)
          tableData.push({
            id: tmpFactory.factoryId + uuid(),
            factoryName: tmpFactory.factoryNameForSelf,
            co2Intensity:
              Math.round(tmpFactory.amountBasedCo2Intensity * 1000) / 1000,
            intensityType: tmpFactory.intensityType,
          });
      } else {
        const tmpFactory =
          factory as PaperFactoryCategory1FactoryDataForDisplay;
        for (const it of tmpFactory.byProduct) {
          if (it.amountBasedCo2Intensity !== undefined)
            tableData.push({
              id: tmpFactory.factoryId + it.productType + uuid(),
              factoryName: tmpFactory.factoryNameForSelf,
              productName:
                it.productShortName === it.productName
                  ? it.productName
                  : it.productShortName + " " + it.productName,
              co2Intensity:
                Math.round(it.amountBasedCo2Intensity * 1000) / 1000,
              intensityType: tmpFactory.intensityType,
            });
        }
      }
    }
  }

  const fileName = `カテゴリ１${
    companyType === "paper-supplier" ? " × 製品別" : ""
  }（原単位）_${category1Data?.year.toFormat("yyyy")}`;

  return {
    tableColumns,
    tableData,
    fileName,
    unit,
  };
};

export const Category1IntensityByProductPage: React.FC<
  Category1IntensityByProductPageProps
> = (props) => {
  const products =
    props.category1Data !== undefined && props.companyType === "paper-supplier"
      ? props.category1Data.factories.flatMap((factory) => {
          const tmpFactory =
            factory as PaperFactoryCategory1FactoryDataForDisplay;
          return tmpFactory.byProduct;
        })
      : [];

  const productTypes = [
    ...new Set(products.map((product) => product.productType)),
  ];

  const productList: DropDownItem[] = productTypes.map((productType) => {
    const product = products.find(
      (product) => product.productType === productType
    );

    const productName = product !== undefined ? product.productName : "";
    const productShortName =
      product !== undefined ? product.productShortName : "";

    return {
      value: productType,
      displayName:
        productName === productShortName
          ? productName
          : productShortName + " " + productName,
    };
  });

  const form = useForm<{ selectedProduct: string }>({
    selectedProduct: {
      type: StringType,
    },
  });

  const dropdownFirstProductType =
    productList.length > 0 ? productList[0].value : undefined;
  useEffect(() => {
    if (dropdownFirstProductType !== undefined) {
      form.fields.selectedProduct.setValue(dropdownFirstProductType);
    }
    // あえて form.fields.selectedProduct.setValue を配列に入れない
    // これは state が変わるたびに別の参照になるため、追加すると無限ループになってしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownFirstProductType]);

  const productDropdownProps: DropdownProps = {
    items: productList,
    ...bindTextField(form.fields.selectedProduct),
  };

  const chartProps = convertCategory1DataToChartProps(
    props.companyType,
    form.fields.selectedProduct.value,
    props.category1Data
  );
  const tableProps = convertCategory1DataToTableProps(
    props.category1Data,
    props.companyType
  );

  return (
    <Category1IntensityByProductTemplate
      isProcessing={props.isProcessing}
      {...chartProps}
      {...tableProps}
      productDropdownProps={productDropdownProps}
      companyType={props.companyType}
    />
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    maxWidth: "none",
  },
}))(Tooltip);

const DataCellWithTooltip = (params: GridRenderCellParams) => {
  return (
    <HtmlTooltip
      title={<Typography variant="body2">{params.value}</Typography>}
      placement="bottom-start"
    >
      <span>{params.value}</span>
    </HtmlTooltip>
  );
};

const getIntensity = (params: GridRenderCellParams) => {
  const { intensityType } = params.row as { intensityType: IntensityType };
  return (
    <Box display="flex">
      <FactoryIntensity intensityType={intensityType} />

      <Box display="flex" flexGrow={1} justifyContent="end">
        {typeof params.value === "number"
          ? params.value.toLocaleString(undefined)
          : ""}
      </Box>
    </Box>
  );
};
